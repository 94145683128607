import React from "react"
import GeneralComp from "../../components/generalComp"
import Layout from "../../components/layout"
import { ragging } from "../../data/parents'-section"
import SEO from "../../components/seo"
import { getWords } from "../../helper-functions"
function AntiRaggingPolicy(props) {
  return (
    <div>
      <Layout>
        <SEO
          title={ragging.title}
          description={getWords(ragging.content, 60)}
        />
        <GeneralComp {...ragging} />
      </Layout>
    </div>
  )
}

export default AntiRaggingPolicy
